export const itemBatchFields = {
  batchNumber: 'batchNumber',
  batchDate: 'batchDate',
  description: 'description',
  itemSKUId: 'itemSKUId',
  algorithmType: 'algorithmType',
  secretKey: 'secretKey',
  startingCode: 'startingCode'
};

export const itemBatchRoutes = {
  list: '/admin/batch',
  add: '/admin/batch/add',
  edit: '/admin/batch/:itemId',
  getEditLink: (itemId: string) => `/admin/batch/${itemId}`,
};

export const productQIDOptions = [
  {
    "label": "Omnivoltaic ovCamp 24Ah",
    "value": "PT004627"
  }, 
  {
    "label": "Omnivoltaic LUMN M300 Series",
    "value": "PT004053"
  },
  {
    "label": "Omnivoltaic ovCatch C500",
    "value": "PT003996"
  },
  {
    "label": "LUMN HOME HS20 Series",
    "value": "PT003997"
  },
  {
    "label": "LUMN HOME HS40 Series",
    "value": "PT003998"
  },
  {
    value: 'PT001730',
    label: 'OvCamp Ov115',
  },
  {
    value: 'PT001731',
    label: 'OvCamp Ov40',
  },
  {
    value: 'PT001727',
    label: 'OvCamp SW460',
  },
  {
    value: 'PT001729',
    label: 'OvCamp SW115',
  },
  {
    value: 'PT001626',
    label: 'OvCamp SW155',
  },
  {
    value: 'PT001624',
    label: 'OvCamp SW40',
  },
  {
    value: 'PT001625',
    label: 'OvCamp SW80',
  },
  {
    value: 'PT001732',
    label: 'OvCamp SW80L',
  },
  {
    value: 'PT001628',
    label: 'OvLumn SW20',
  },
  {
    value: 'PT001619',
    label: 'Omnivoltaic Camp 3Ah',
  },
  {
    value: 'PT001620',
    label: 'Omnivoltaic Camp 6Ah',
  },
  {
    value: 'PT001728',
    label: 'Omnivoltaic Camp 9Ah',
  },
  {
    value: 'PT001621',
    label: 'Omnivoltaic Camp 12Ah',
  },
  {
    value: 'PT001879',
    label: 'Omnivoltaic Camp 18Ah',
  },
  {
    value: 'PT001880',
    label: 'Omnivoltaic Camp 42Ah',
  },
  {
    value: 'PT001881',
    label: 'Omnivoltaic Camp 48Ah24V',
  },
  {
    value: 'PT001622',
    label: 'Omnivoltaic Camp 36Ah',
  },
  {
    value: 'PT001623',
    label: 'Omnivoltaic Lumn M400',
  },
  {
    value: 'PT001882',
    label: 'Omnivoltaic Lumn M600',
  },
  {
    value: 'PT001883',
    label: 'Omnivoltaic Lumn L500',
  },
  {
    value: 'PT001884',
    label: 'Omnivoltaic Lumn L300',
  },
  {
    value: 'PT001885',
    label: 'Omnivoltaic Lumn L190',
  },
  {
    value: 'PT001886',
    label: 'Omnivoltaic Catch C300',
  },
  {
    value: 'PT005546',
    label: 'Inverter 24V',
  },
  {
    value: 'PT005547',
    label: 'Inverter 48V',
  },
  {
    value: 'PT005548',
    label: 'EBike VCU',
  }, 
  {
    value: 'PT005557',
    label: 'HSBat24'
  },
  {
    value: 'PT005558',
    label: 'HSBat48'
  },
  {
    value: "PT005615",
    label: "OASIS Series"
  }
];