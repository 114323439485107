import { routes as batchRoutes } from "./batch"
import { routes as codeGenRoutes } from "./codeGenerator"
import { routes as adminRoutes } from "./dashboard"
import { routes as firmwareRoutes } from "./firmware"
import { routes as fleetRoutes } from "./fleet"
import { routes as itemRoutes } from "./item"
import {routes as skuRoutes} from './sku'
import {routes as codeEventsRoutes} from "./codeEvent"
export const thingRoutes = [
   batchRoutes,
   codeGenRoutes,
   adminRoutes,
   firmwareRoutes,
   fleetRoutes,
   itemRoutes,
   skuRoutes,
   codeEventsRoutes
]