import { IProps } from '@components/messages/types';
import React, { createContext, useState } from 'react';
import AssetAccountProvider from '../../../accounts/app/assetAccount/context/AssetAccountProvider';
import ItemBatchProvider from '../batch/context/BatchProvider';
import CodeEventProvider from '../codeEvent/context/CodeEventProvider';
import CodeGeneratorProvider from '../codeGenerator/context/CodeGeneratorProvider';
import CustomerProvider from '../../../clientService/app/customer/context/CustomerProvider';
import CustomAgentProvider from 'apps/clientService/app/agents/context/CustomAgentProvider';
import CustomDistributorStaffProvider from "apps/clientService/app/distributorStaff/context/CustomDistributorStaffProvider";
import DistributorProvider from '../../../clientService/app/distributor/context/DistributorProvider';
import ItemFirmwareProvider from '../firmware/context/FirmwareProvider';
import FleetProvider from '../fleet/context/FleetProvider';
import ItemProvider from '../item/context/ItemProvider';
import LoginUserContext from '../../../../admin/loginUser/context/LoginUserContext';
import OrganizationProvider from '../../../clientService/app/organization/context/OrganisationProvider';
import ItemSKUProvider from '../sku/context/SKUProvider';
import SupplierProvider from '../../../clientService/app/supplier/context/SupplierProvider';
import AvatarProvider from '../avatar/context/AvatarProvider';
import ServicerProvider from 'apps/clientService/app/servicer/context/ServicerProvider';
import ClientServiceProvider from 'apps/clientService/app/clientService/context/ClientServiceProvider';
import ClientServicePermissionsProvider from 'apps/clientService/app/clientServicePermissions/context/ClientServicePermissionsProvider';
import ClientExclusiveServicerProvider from 'apps/clientService/app/clientExclusiveServicer/context/ClientExclusiveServicerProvider';
import AssetAccountTempDataProvider from 'apps/accounts/app/angazaReview/context/EventProvider';
import PayPlanTemplateProvider from 'apps/accounts/app/payPlanTemplate/context/EventProvider';
import MessageGroupProvider from 'apps/accounts/app/messageGroup/context/MessageGroupProvider';
import MessageTemplateProvider from 'apps/accounts/app/mesageTemplate/context/MessageTemplateProvider';
import PaymentsProvider from 'apps/accounts/app/payments/context/PaymentsProvider';
import PeopleViewProvider from 'apps/accounts/app/peopleView/context/PeopleViewProvider';
export interface IGlobalSimulatorContext {
  tableActionLoading: boolean;
  setTableActionLoading: (loading: boolean) => void;
}
export const GlobalSimulatorContext = createContext<IGlobalSimulatorContext>(
  {} as IGlobalSimulatorContext
);

const GlobalSimulatorProvider: React.FC<IProps> = ({ children }) => {
  const [tableActionLoading, setTableActionLoading] = useState(false);
  const value = React.useMemo(
    () => ({
      tableActionLoading,
      setTableActionLoading,
    }),
    [tableActionLoading]
  );
  return (
    <GlobalSimulatorContext.Provider value={value}>
      {children}
    </GlobalSimulatorContext.Provider>
  );
};

const SimulatorAppProvider: React.FC<IProps> = ({ children }) => (
  <GlobalSimulatorProvider>
    <ItemSKUProvider>
      <ItemBatchProvider>
        <FleetProvider>
          <CustomAgentProvider>
            <ItemProvider>
              <CodeGeneratorProvider>
                <CodeEventProvider>
                  <OrganizationProvider>
                    <LoginUserContext>
                      <CustomerProvider>
                        <AssetAccountProvider>
                          <AssetAccountTempDataProvider>
                            <PayPlanTemplateProvider>
                              <MessageGroupProvider>
                                <MessageTemplateProvider>
                                  <PaymentsProvider>
                                    <PeopleViewProvider>
                                      <ItemFirmwareProvider>
                                        <CustomDistributorStaffProvider>
                                          <DistributorProvider>

                                            <AvatarProvider>
                                              <ClientServiceProvider>
                                                <ServicerProvider>
                                                  <ClientServicePermissionsProvider>
                                                    <ClientExclusiveServicerProvider>
                                                      <SupplierProvider>{children}</SupplierProvider>
                                                    </ClientExclusiveServicerProvider>
                                                  </ClientServicePermissionsProvider>
                                                </ServicerProvider>
                                              </ClientServiceProvider>
                                            </AvatarProvider>
                                          </DistributorProvider>
                                        </CustomDistributorStaffProvider>
                                      </ItemFirmwareProvider>
                                    </PeopleViewProvider>
                                  </PaymentsProvider>
                                </MessageTemplateProvider>
                              </MessageGroupProvider>
                            </PayPlanTemplateProvider>
                          </AssetAccountTempDataProvider>
                        </AssetAccountProvider>
                      </CustomerProvider>
                    </LoginUserContext>
                  </OrganizationProvider>
                </CodeEventProvider>
              </CodeGeneratorProvider>
            </ItemProvider>
          </CustomAgentProvider>
        </FleetProvider>
      </ItemBatchProvider>
    </ItemSKUProvider>
  </GlobalSimulatorProvider >
);

export default SimulatorAppProvider;
