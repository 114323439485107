import React from 'react';
import { USER_TYPES } from 'admin/auth/constants/auth';
// @material-ui/icons
import EventNoteIcon from '@material-ui/icons/EventNote';
import ApartmentIcon from '@material-ui/icons/Apartment';
import SettingsIcon from '@material-ui/icons/Settings';
import ResetPassword from 'admin/auth/containers/ResetPassword';
import ItemIndex from 'apps/things/app/item';
import ResetPasswordToken from 'admin/auth/containers/ResetPasswordToken';
const ClientExclusiveServiceIndex = React.lazy(() => import('apps/clientService/app/clientExclusiveServicer'))
// const LegacyProtocolVersionIndex = React.lazy(() => import('apps/migration/app/LegacyProtocolVersion'));
const Register = React.lazy(() => import('admin/auth/containers/Register'));
const StaffAgentRegister = React.lazy(() => import('admin/auth/containers/StaffAgentRegister'))
const Login = React.lazy(() => import('admin/auth/containers/Login'));

const FleetIndex = React.lazy(() => import('apps/things/app/fleet'));

const CustomerIndex = React.lazy(() =>
  import('apps/clientService/app/customer')
);

const AgentsIndex = React.lazy(() => 
  import('apps/clientService/app/agents')
)

const DistributorStaffIndex = React.lazy(() => 
  import('apps/clientService/app/distributorStaff')
)
const AssetAccountIndex = React.lazy(() =>
  import('apps/accounts/app/assetAccount')
);
const PaymentsIndex = React.lazy(() =>
  import('apps/accounts/app/payments')
);
const PayPlanTemplateIndex = React.lazy(() =>
  import('apps/accounts/app/payPlanTemplate')
);
const AssetAccountTempDataIndex = React.lazy(() =>
  import('apps/accounts/app/angazaReview')
);
const MessageGroupIndex = React.lazy(() => 
import('apps/accounts/app/messageGroup')
);
const MessageTemplateIndex = React.lazy(() => 
  import('apps/accounts/app/mesageTemplate')
);


const DistributorIndex = React.lazy(() =>
  import('apps/clientService/app/distributor')
);


const EventIndex = React.lazy(() => import('apps/events/app/events'));
const ItemEventIndex = React.lazy(() => import('apps/events/app/itemEvents'));
const SignalIndex = React.lazy(() => import('apps/events/app/signal'));
const BrokerIndex = React.lazy(() => import('apps/events/app/broker'));
const PublisherIndex = React.lazy(() => import('apps/events/app/publisher'));

const dashRoutes = [
  {
    path: '/login-page',
    name: 'Login Page',
    rtlName: 'هعذاتسجيل الدخول',
    mini: 'L',
    rtlMini: 'هعذا',
    component: Login,
    layout: '/auth',
  },
  {
    path: '/register-page/:token',
    name: 'Register Page',
    rtlName: 'تسجيل',
    mini: 'R',
    rtlMini: 'صع',
    component: Register,
    layout: '/auth',
  },
  {
    path: '/register-page/distributor_staff/:token',
    name: 'Register Page',
    rtlName: 'تسجيل',
    mini: 'R',
    rtlMini: 'صع',
    component: StaffAgentRegister,
    layout: '/auth',
  },
  {
    path: '/passwordreset/:token',
    name: 'Reset Password Page',
    rtlName: 'تسجيل',
    mini: 'R',
    rtlMini: 'صع',
    component: ResetPasswordToken,
    layout: '/auth',
  },
  {
    path: '/reset-password-page',
    name: 'Reset Password Page',
    rtlName: 'تسجيل',
    mini: 'R',
    rtlMini: 'صع',
    component: ResetPassword,
    layout: '/auth',
  },
  {
    collapse: true,
    path: '/settings',
    name: 'My Settings',
    rtlName: 'الحاجيات',
    icon: SettingsIcon,
    state: 'settings',
    permissions: [USER_TYPES.DISTRIBUTOR],
    views: [
      {
        path: '/distributors/settings',
        name: 'Code Generation',
        rtlName: 'عالتسعير',
        mini: 'C',
        rtlMini: 'ع',
        component: DistributorIndex,
        layout: '/admin',
        permissions: [USER_TYPES.DISTRIBUTOR],
      },
    ],
  },
  //

  {
    collapse: true,
    path: '/accounts',
    name: 'Accounts',
    rtlName: 'الحاجيات',
    icon: ApartmentIcon,
    state: 'accounts',
    permissions: [USER_TYPES.DISTRIBUTOR, USER_TYPES.SERVICER, USER_TYPES.DISTRIBUTOR_STAFF, USER_TYPES.GENERAL_AGENT],
    views: [
      {
        path: '/client-exclusive-servicer',
        name: 'Client Exclusive Servicer',
        rtlName: '---',
        mini: 'C',
        component: ClientExclusiveServiceIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SERVICER]
      },
      {
        path: '/thing-fleet/client-fleet',
        name: 'Fleets',
        rtlName: 'عالتسعير',
        mini: 'F',
        rtlMini: 'ع',
        component: FleetIndex,
        layout: '/admin',
        permissions: [USER_TYPES.DISTRIBUTOR, USER_TYPES.SERVICER, USER_TYPES.DISTRIBUTOR_STAFF],
      },
      {
        path: '/item/distributor-items',
        name: 'Items',
        rtlName: 'عالتسعير',
        mini: 'I',
        rtlMini: 'ع',
        component: ItemIndex,
        layout: '/admin',
        permissions: [USER_TYPES.DISTRIBUTOR, USER_TYPES.SERVICER, USER_TYPES.DISTRIBUTOR_STAFF, USER_TYPES.GENERAL_AGENT],
      },
      {
        path: '/customers',
        name: 'Customer',
        rtlName: 'عالتسعير',
        mini: 'C',
        rtlMini: 'ع',
        component: CustomerIndex,
        layout: '/admin',
        permissions: [USER_TYPES.DISTRIBUTOR, USER_TYPES.SERVICER, USER_TYPES.DISTRIBUTOR_STAFF, USER_TYPES.GENERAL_AGENT],
      },
      {
        path: '/distributor-staff',
        name: 'Distributor Staff',
        rtlName: 'عالتسعير',
        mini: 'A',
        rtlMini: 'ع',
        component: DistributorStaffIndex,
        layout: '/admin',
        permissions: [ USER_TYPES.DISTRIBUTOR],
      },
      {
        path: '/agents',
        name: 'Agents',
        rtlName: 'عالتسعير',
        mini: 'A',
        rtlMini: 'ع',
        component: AgentsIndex,
        layout: '/admin',
        permissions: [ USER_TYPES.DISTRIBUTOR_STAFF],
      },
      {
        path: '/asset-accounts',
        name: 'Asset Accounts',
        rtlName: 'عالتسعير',
        mini: 'A',
        rtlMini: 'ع',
        component: AssetAccountIndex,
        layout: '/admin',
        permissions: [USER_TYPES.DISTRIBUTOR, USER_TYPES.SERVICER, USER_TYPES.DISTRIBUTOR_STAFF, USER_TYPES.GENERAL_AGENT],
      },
      {
        path: '/payments',
        name: 'Payments',
        rtlName: 'عالتسعير',
        mini: 'A',
        rtlMini: 'ع',
        component: PaymentsIndex,
        layout: '/admin',
        permissions: [USER_TYPES.DISTRIBUTOR, USER_TYPES.DISTRIBUTOR_STAFF],
      },
      {
        path: '/payplan-template',
        name: 'PayPlan Template',
        rtlName: 'عالتسعير',
        mini: 'P',
        rtlMini: 'ع',
        component: PayPlanTemplateIndex,
        layout: '/admin',
        permissions: [USER_TYPES.DISTRIBUTOR, USER_TYPES.DISTRIBUTOR_STAFF],
      },
      {
        path: '/message-group',
        name: 'Message Group',
        rtlName: 'عالتسعير',
        mini: 'M',
        rtlMini: 'ع',
        component: MessageGroupIndex,
        layout: '/admin',
        permissions: [USER_TYPES.DISTRIBUTOR, USER_TYPES.DISTRIBUTOR_STAFF],
      },
      {
        path: '/message-template',
        name: 'Message Template',
        rtlName: 'عالتسعير',
        mini: 'T',
        rtlMini: 'ع',
        component: MessageTemplateIndex,
        layout: '/admin',
        permissions: [USER_TYPES.DISTRIBUTOR, USER_TYPES.DISTRIBUTOR_STAFF],
      },

      {
        path: '/angaza-review',
        name: 'Angaza Review',
        rtlName: 'عالتسعير',
        mini: 'R',
        rtlMini: 'ع',
        component: AssetAccountTempDataIndex,
        layout: '/admin',
        permissions: [USER_TYPES.DISTRIBUTOR, USER_TYPES.DISTRIBUTOR_STAFF],
      },
    ],
  },


  {
    collapse: true,
    path: '/events',
    name: 'Event',
    rtlName: 'الحاجيات',
    icon: EventNoteIcon,
    state: 'events',
    permissions: [USER_TYPES.DISTRIBUTOR, USER_TYPES.DISTRIBUTOR_STAFF],
    views: [
      {
        path: '/events-events',
        name: 'Events',
        rtlName: 'الحاجيات',
        component: EventIndex,
        mini: 'E',
        layout: '/admin',
        permissions: [ USER_TYPES.DISTRIBUTOR, USER_TYPES.DISTRIBUTOR_STAFF],
      },

      {
        path: '/events-items',
        name: 'Item Events',
        rtlName: 'الحاجيات',
        component: ItemEventIndex,
        mini: 'I',
        layout: '/admin',
        permissions: [ USER_TYPES.DISTRIBUTOR, USER_TYPES.DISTRIBUTOR_STAFF],
      },
      {
        path: '/events-signal',
        name: 'Signal',
        rtlName: 'الحاجيات',
        component: SignalIndex,
        mini: 'S',
        layout: '/admin',
        permissions: [ USER_TYPES.DISTRIBUTOR, USER_TYPES.DISTRIBUTOR_STAFF],
      },
      {
        path: '/events-broker',
        name: 'Broker',
        rtlName: 'عالتسعير',
        mini: 'B',
        rtlMini: 'ع',
        component: BrokerIndex,
        layout: '/admin',
        permissions: [ USER_TYPES.DISTRIBUTOR, USER_TYPES.DISTRIBUTOR_STAFF],
      },
      {
        path: '/events-publisher',
        name: 'Publisher',
        rtlName: 'عالتسعير',
        mini: 'P',
        rtlMini: 'ع',
        component: PublisherIndex,
        layout: '/admin',
        permissions: [ USER_TYPES.DISTRIBUTOR, USER_TYPES.DISTRIBUTOR_STAFF],
      },
    ],
  },

];
export default dashRoutes;
