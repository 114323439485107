import { combineReducers } from 'redux';
import { itemsReducer } from './itemsReducer';
import { userPrefReducer } from './userPrefReducer';
import { provisionReducer } from './provisionReducer';
const rootReducer = combineReducers({
  userPref: userPrefReducer,
  provision: provisionReducer,
  items: itemsReducer,
});

export default rootReducer;
