import * as yup from 'yup';
import { itemSKUFields } from './sku/constants';
import { itemBatchFields } from './batch/constants';
import { itemFirmwareFields } from './firmware/constants';
import { itemFields } from './item/constants';
import { codeGeneratorFields } from './codeGenerator/constants';
import { itemFields as orgFields } from 'apps/clientService/app/organization/constants';
import { itemFields as _orgFields } from 'apps/clientService/app/supplier/constants';
import { itemFields as fleetFields } from 'apps/things/app/fleet/constants';
import { itemFields as loginUserFields } from 'admin/loginUser/constants';
import { itemFields as customerFields } from 'apps/clientService/app/customer/constants';
import { itemFields as assetAccountFields, paymentPlanFields } from 'apps/accounts/app/assetAccount/constants';
import { itemFields as eventFields } from 'apps/events/app/events/constants';
import { itemFields as brokerFields } from 'apps/events/app/broker/constants';
import { itemFields as signalFields } from 'apps/events/app/signal/constants';
import { itemFields as publisherFields } from 'apps/events/app/publisher/constants';
import { itemFields as serviceFields } from 'apps/clientService/app/servicer/constants';


const { addItem } = itemFields;

export const digitsAndAlphabetsRequired = yup
  .string()
  .required('Field is required')
  .matches(
    /^[aA-zZ\s0-9_.-]+$/,
    'Only alphabets and numbers are allowed for this field '
  );

export const stringRequired = yup.string().required('Field is required');
export const emailRequired = yup.string().email().required('Field is required');
export const numberRequired = yup
  .number()
  .max(999999)
  .required('Field is required');

export const positiveNumberRequired = yup
  .number()
  .positive()
  .min(0)
  .required('Field is required');

const phoneNumberRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s/0-9]{6,}$/g;


export const phoneRequired = yup
  .string()
  .required('Phone number is required')
  .matches(phoneNumberRegex, 'Phone number is not valid');

const attributeValidation = yup.array().of(
  yup.object().shape({
    prop: stringRequired,
    value: stringRequired,
    meta: stringRequired,
  })
);

export const assignDistributorToServicer = () => yup.object().shape({
  servicerId: stringRequired,
  serviceType: stringRequired,
  distributorId: stringRequired
})

export const updateServicerValidationSchema = () => yup.object().shape({
  serviceId: stringRequired
})
export const createServicer = () => yup.object().shape({
  [serviceFields.contactRole]: stringRequired,
  [serviceFields.email]: emailRequired,
  [serviceFields.roleId]: stringRequired,
  servicerAddress:  yup.object().shape({
    city: stringRequired,
    country: stringRequired,
    postcode: stringRequired,
    street: stringRequired,
    unit: stringRequired,
    srpc: stringRequired,
    locationAddressDto: yup.object().shape({
      inputAddressLatitude: stringRequired,
      inputAddressLongitude: stringRequired,
    }),
  }),
})
export const createAvatarValidationSchema = () =>
  yup.object().shape({
    shadow: stringRequired,
    lastPub: stringRequired,
    lastSub: stringRequired,
    lastSync: stringRequired,
    att: attributeValidation,
    cmd: attributeValidation,
    dia: attributeValidation,
    dta: attributeValidation,
    sts: attributeValidation,
  });
export const addItemSKUValidationSchema = () =>
  yup.object().shape({
    [itemSKUFields.addItemSKU.skuName]: stringRequired,
    [itemSKUFields.addItemSKU.productBase]: stringRequired,
    [itemSKUFields.addItemSKU.gatt]: yup.object().shape({
      name: yup.string(),
      attributes: yup.array().of(
        yup.object().shape({
          prop: stringRequired,
          value: stringRequired,
          meta: stringRequired,
        })
      ),
    }),
  });

export const addItemValidationSchema = () =>
  yup.object().shape({
    [addItem.oemItemID]: stringRequired,
    [addItem.itembatchId]: stringRequired,
    [addItem.itemfirmwareId]: stringRequired,
    [addItem.totalItems]: numberRequired,
  });
  export const paymentPlanValidationSchema = yup.object().shape({
    [paymentPlanFields.freecodePrice]: numberRequired,
    [paymentPlanFields.hourPrice]: numberRequired,
  });


export const addLegacyItemValidationSchema = () =>
  yup.object().shape({
    productBatchID: stringRequired,
    productModelID: stringRequired,
    productItemOEM_SN: stringRequired,
    firmwareVersion: stringRequired,
    [addItem.totalItems]: numberRequired,
  });
export const creditAccountValidationSchema = () =>
  yup.object().shape({
    amount: positiveNumberRequired,
    notes: stringRequired,
    action: stringRequired,
    datetime: stringRequired,
  });
export const archiveSignalValidationSchema = () =>
  yup.object().shape({
    start: stringRequired,
    end: stringRequired,
  });
export const debitAccountValidationSchema = () =>
  yup.object().shape({
    balance: positiveNumberRequired,
  });

export const addLegacyBatchValidationSchema = () =>
  yup.object().shape({
    productBatchNumber: stringRequired,
  });

export const addLegacyCategoryValidationSchema = () =>
  yup.object().shape({
    productCategoryName: stringRequired,
  });

export const addLegacyFamilyValidationSchema = () =>
  yup.object().shape({
    productFamilyName: stringRequired,
    productFamilyDescription: stringRequired,
    productCategoryID: stringRequired,
  });

export const addLegacyModelValidationSchema = () =>
  yup.object().shape({
    productModelName: stringRequired,
    productModelCost: stringRequired,
    productFamilyID: stringRequired,
  });

export const itemBatchFieldsValidationSchema = () =>
  yup.object().shape({
    [itemBatchFields.batchDate]: stringRequired,
    [itemBatchFields.itemSKUId]: stringRequired,
    [itemBatchFields.batchNumber]: stringRequired,
    [itemBatchFields.description]: stringRequired,
  });

export const itemFirmwareFieldsValidationSchema = () =>
  yup.object().shape({
    [itemFirmwareFields.description]: stringRequired,
    [itemFirmwareFields.codeSystem]: stringRequired,
    [itemFirmwareFields.version]: stringRequired,
  });

export const createCodeGeneratorFieldsValidationSchema = () =>
  yup.object().shape({
    [codeGeneratorFields.hashRoot]: stringRequired,
    [codeGeneratorFields.hashTop]: stringRequired,
    [codeGeneratorFields.codeCount]: stringRequired,
    [codeGeneratorFields.hashIndex]: stringRequired,
    [codeGeneratorFields.codeReversalCount]: stringRequired,
    [codeGeneratorFields.codeSystemId]: stringRequired,
  });

  export const updateItemFleetCodeGenFieldsValidationSchema = () =>
  yup.object().shape({
    resetCodeCount: numberRequired,
    freeCodeCount: numberRequired,
  });


export const categoryValidationSchema = () =>
  yup.object().shape({
    name: stringRequired,
    thumb: stringRequired,
  });

export const selectionValidationSchema = () =>
  yup.object().shape({
    selectionName: stringRequired,
  });

export const applicationSiteValidationSchema = () =>
  yup.object().shape({
    websiteUrl: stringRequired,
  });
export const fleetValidationSchema = yup.object().shape({
  [fleetFields.assignDate]: stringRequired,
  [fleetFields.description]: stringRequired,
  [fleetFields.fleetName]: stringRequired,
});


export const fleetCodeGenSchema = yup.object().shape({
  [fleetFields.maxCreditStackCodeEvents]: numberRequired,
  [fleetFields.maxCreditStackDays]: numberRequired
});

export const createPublisherValidationSchema = yup.object().shape({
  [publisherFields.broker]: stringRequired,
  [publisherFields.topic]: stringRequired,
});

export const createBrokerValidationSchema = yup.object().shape({
  [brokerFields.name]: stringRequired,
  [brokerFields.url]: stringRequired,
});

export const createSignalValidationSchema = yup.object().shape({
  [signalFields.jmp]: stringRequired,
  [signalFields.time]: stringRequired,
});
export const createEventValidationSchema = yup.object().shape({
  [eventFields.action]: yup.object().shape({
    type: stringRequired,
    label: stringRequired,
    description: stringRequired,
  }),
  [eventFields.actor]: yup.object().shape({
    description: stringRequired,
    name: stringRequired,
    type: stringRequired,
  }),
  [eventFields.location]: yup.object().shape({
    latitude: numberRequired,
    longitude: numberRequired,
  }),
  [eventFields.time]: stringRequired,
  [eventFields.signal]: stringRequired,
});
export const organizationValidationSchema = yup.object().shape({
  orgName: stringRequired,
  [orgFields.orgAddress]: yup.object().shape({
    city: stringRequired,
    country: stringRequired,
    postcode: stringRequired,
    street: stringRequired,
    unit: stringRequired,
    srpc: stringRequired,
    locationAddressDto: yup.object().shape({
      inputAddressLatitude: stringRequired,
      inputAddressLongitude: stringRequired,
    }),
  }),
});

export const organizationUpdateValidationSchema = yup.object().shape({
  orgName: stringRequired,
  [orgFields.orgAddress]: yup.object().shape({
    city: stringRequired,
    country: stringRequired,
    postcode: stringRequired,
    street: stringRequired,
    unit: stringRequired,
    srpc: stringRequired,
    UpdateLocationAddressDto: yup.object().shape({
      lat: stringRequired,
      lng: stringRequired,
    }),
  }),
});

export const supplierValidationSchema = yup.object().shape({
  [_orgFields.supplierAddress]: yup.object().shape({
    city: stringRequired,
    country: stringRequired,
    postcode: stringRequired,
    street: stringRequired,
    unit: stringRequired,
    srpc: stringRequired,
  }),
});

export const assetAccountValidationSchema = yup.object().shape({
  [assetAccountFields.itemId]: stringRequired,
  [assetAccountFields.credit]: yup.object().shape({
    balance: numberRequired,
    currency: stringRequired,
    customerId: stringRequired,
  }),
});

export const customerValidationSchema = yup.object().shape({
  [customerFields.clientPersonName]: stringRequired,
  clientContactPerson: yup.object().shape({
    email: stringRequired,
    phone: phoneRequired,
    social: stringRequired,
  }),
  clientPersonAddress: yup.object().shape({
    city: stringRequired,
    country: stringRequired,
    postcode: stringRequired,
    street: stringRequired,
    unit: stringRequired,
    srpc: stringRequired,
    locationAddressDto: yup.object().shape({
      inputAddressLatitude: stringRequired,
      inputAddressLongitude: stringRequired,
    }),
  }),
});

export const distributorStaffValidationSchema = yup.object().shape({
  firstName: stringRequired,
  lastName: stringRequired,
  email: stringRequired,
  gender: stringRequired,
  age: numberRequired,
  hireDate: stringRequired,
  officeAddress: yup.object().shape({
    city: stringRequired,
    country: stringRequired,
    postcode: stringRequired,
    street: stringRequired,
    unit: stringRequired,
    srpc: stringRequired,
    locationAddressDto: yup.object().shape({
      inputAddressLatitude: stringRequired,
      inputAddressLongitude: stringRequired,
    }),
  }),
  staffAddress: yup.object().shape({
    city: stringRequired,
    country: stringRequired,
    postcode: stringRequired,
    street: stringRequired,
    unit: stringRequired,
    srpc: stringRequired,
    locationAddressDto: yup.object().shape({
      inputAddressLatitude: stringRequired,
      inputAddressLongitude: stringRequired,
    }),
  }),
  staffContact: yup.object().shape({
    phone: stringRequired,
    social: stringRequired,
  }),
})

export const agentValidationSchema = yup.object().shape({
  firstName: stringRequired,
  lastName: stringRequired,
  email: stringRequired,
  gender: stringRequired,
  age: numberRequired,
  hireDate: stringRequired,
  officeAddress: yup.object().shape({
    city: stringRequired,
    country: stringRequired,
    postcode: stringRequired,
    street: stringRequired,
    unit: stringRequired,
    srpc: stringRequired,
    locationAddressDto: yup.object().shape({
      inputAddressLatitude: stringRequired,
      inputAddressLongitude: stringRequired,
    }),
  }),
  agentAddress: yup.object().shape({
    city: stringRequired,
    country: stringRequired,
    postcode: stringRequired,
    street: stringRequired,
    unit: stringRequired,
    srpc: stringRequired,
    locationAddressDto: yup.object().shape({
      inputAddressLatitude: stringRequired,
      inputAddressLongitude: stringRequired,
    }),
  }),
  agentContact: yup.object().shape({
    phone: stringRequired,
    social: stringRequired,
  }),

  typeOfAgent: stringRequired,
  agentType: stringRequired
})

export const loginUserValidationSchema = yup.object().shape({
  [loginUserFields.contactRole]: stringRequired,
  [loginUserFields.email]: stringRequired,
  [loginUserFields.roleId]: stringRequired,
  distributorAddress: yup.object().shape({
    city: stringRequired,
    country: stringRequired,
    postcode: stringRequired,
    street: stringRequired,
    unit: stringRequired,
    srpc: stringRequired,
    locationAddressDto: yup.object().shape({
      inputAddressLatitude: stringRequired,
      inputAddressLongitude: stringRequired,
    }),
  }),
});
