import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { history } from '../routes';
import rootReducer from './reducers';

import { UserPrefEpics, RootAction, RootState } from './epics/userPrefEpics';
import { HttpService } from './services/HttpService';

const loggerMiddleware = createLogger();

export const rootEpic = combineEpics(
  UserPrefEpics.toggleColumn
);
// more epic functions goes here

const persistConfig = {
  key: 'root',
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const epicMiddleware = createEpicMiddleware<RootAction, RootAction, RootState>({
  dependencies: {
    history,
    ajaxGet: HttpService.get,
    ajaxPost: HttpService.post,
    ajaxPut: HttpService.put,
    ajaxDel: HttpService.delete,
  },
});

// @ts-ignore
const middlewares = applyMiddleware(epicMiddleware, loggerMiddleware);

const createStoreWithMiddleware = middlewares;

// eslint-disable-next-line
export default () => {
  let store = createStore(persistedReducer, createStoreWithMiddleware);
  let persister = persistStore(store);
  return { store, persister };
};
epicMiddleware.run(rootEpic);
